import React, { useMemo, useState } from "react";

import { getErrorMessage } from "@utils/errors";

import { emailValidator } from "@autocorp/web-core-utils/lib/emailValidator";

import { useRequestLead } from "@api/id";
import { ProductType } from "@api/products";
import { useAuth } from "@api/auth";

import { useFeedbackContext } from "~/components/Feedback/context";
import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Typography } from "@material-ui/core";
import { Input as MUIInput } from "@material-ui/core";

import type {
	IRequestIdVerificationState,
	IRequestIdVerificationFormProps,
} from "@id/types";

import { useStyles } from "./styles";

type RequestIdVerificationStateHandlerKeys = keyof IRequestIdVerificationState;
type RequestIdVerificationStateHandlers = Record<
	RequestIdVerificationStateHandlerKeys,
	(val: string) => void
>;

const defaultMessage = `Hey, we need to verify you have a valid drivers license to move forward. Please follow this link on your mobile device to upload your id.`;

const RequestIdVerificationForm: React.FC<IRequestIdVerificationFormProps> = ({
	onComplete,
}) => {
	const { selectedCompany } = useAuth();
	const {
		actions: { adminSubmitLead },
	} = useRequestLead();
	const styles = useStyles();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [inputValues, updateValue] = useState<IRequestIdVerificationState>({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		message: defaultMessage,
	});

	const [value, setValue] = useState("");
	const [additionalInformationInput, setAdditionalInformationInput] =
		useState("");

	const additionalInformationValue = (value: string) => {
		if (value === "other") {
			return additionalInformationInput;
		}
		return value;
	};

	const additionalInformation = additionalInformationValue(value);

	const handleRadioInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setAdditionalInformationInput(event.target.value);
	};
	const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	const { handleOpenFeedback } = useFeedbackContext();

	const inputHandler = useMemo<RequestIdVerificationStateHandlers>(() => {
		const scopedHandler =
			(key: RequestIdVerificationStateHandlerKeys) => (val: string) => {
				updateValue((state) => ({
					...state,
					[key]: val,
				}));
			};
		return Object.keys(inputValues).reduce(
			(acc, key) => ({
				...acc,
				[key as RequestIdVerificationStateHandlerKeys]: scopedHandler(
					key as RequestIdVerificationStateHandlerKeys,
				),
			}),
			{} as RequestIdVerificationStateHandlers,
		);
	}, [updateValue, inputValues]);

	const { phoneNumber, message, firstName, lastName, email } = inputValues;

	const handleSubmit = async () => {
		let complete = false;
		setError("");
		if (!selectedCompany) {
			return;
		}
		if (!phoneNumber) {
			return setError("Phone Number is mandatory!");
		}
		if (email && !emailValidator.test(inputValues.email)) {
			return setError("Please enter a valid email address");
		}
		try {
			setLoading(true);
			const leadRequest = await adminSubmitLead(
				selectedCompany.id,
				phoneNumber,
				[ProductType.IdTool],
				message,
				firstName,
				lastName,
				email,
				additionalInformation,
			);
			const isDuplicatedRequest =
				leadRequest?.data?.adminSubmitLead?.deduplicated;
			if (isDuplicatedRequest) {
				handleOpenFeedback({
					message: "Already sent request to this phone number",
					severity: "warning",
				});
			} else {
				handleOpenFeedback({
					message: "Request sent successfully!",
					severity: "success",
				});
			}
			complete = true;
			onComplete();
		} catch (error) {
			console.log(error);
			setError(getErrorMessage(error));
		} finally {
			if (!complete) setLoading(false);
		}
	};

	const isCompanyArchived = selectedCompany?.archived;

	return (
		<BaseForm error={error} className={styles.root}>
			<Input
				label="First Name"
				placeholder="First Name"
				update={inputHandler.firstName}
				id={"input-first-name"}
				value={inputValues.firstName}
			/>
			<Input
				label="Last Name"
				placeholder="Last Name"
				update={inputHandler.lastName}
				id={"input-last-name"}
				value={inputValues.lastName}
			/>
			<Input
				label="Email"
				placeholder="Email"
				update={inputHandler.email}
				id={"input-email"}
				value={inputValues.email}
			/>
			<Input
				mandatory
				label="Phone Number"
				placeholder="Phone Number"
				update={inputHandler.phoneNumber}
				id={"input-phone-number"}
				value={inputValues.phoneNumber}
			/>
			<Input
				label="Message"
				placeholder="Message"
				update={inputHandler.message}
				id={"input-message"}
				value={inputValues.message}
				multiline
			/>
			<FormControl
				className={styles.additionalInformationForm}
				component="fieldset"
			>
				<Typography
					className={styles.additionalInformationLabel}
					variant="caption"
				>
					I am requesting ID for
				</Typography>
				<RadioGroup
					aria-label="additionalQuestion"
					name="additionalQuestion"
					value={value}
					onChange={handleChangeRadio}
				>
					<FormControlLabel
						value="financeApplication"
						control={<Radio />}
						label="Finance Application"
					/>
					<FormControlLabel
						value="testDrive"
						control={<Radio />}
						label="Test Drive"
					/>
					<FormControlLabel
						value="other"
						control={<Radio />}
						label={
							<MUIInput
								placeholder="Other"
								onChange={handleRadioInputChange}
								value={additionalInformationInput}
							/>
						}
					/>
				</RadioGroup>
			</FormControl>
			<Button
				disabled={isCompanyArchived}
				submit
				label={
					isCompanyArchived
						? "Service Unavailable"
						: "SEND REQUEST VIA SMS"
				}
				onClick={handleSubmit}
				loading={loading}
				className={styles.button}
			/>
		</BaseForm>
	);
};

export default RequestIdVerificationForm;
